<template>
  <div class="container">
    <div class="content">
      <section class="section inner-H">
        <div class="flow">
          <h2 class="headline">会員登録の流れ</h2>
          <img class="flow-step" src="/assets/img/eKYC/registration_flow_step2_ekyc.png" />
          <img class="flow-bar" src="/assets/img/account/flow-bar02.png" />
        </div>
      </section>
      <section class="section inner-H inner-V bg-gray">
        <h3 class="ta-c mb40">
          運転免許証とご自分をスマホで撮影するだけで、<br />
          本人確認ができます
        </h3>
        <img class="eKYC mb30 bg-white" src="/assets/img/eKYC/screen_illust_ekyc.png" />
        <ActionButton class="btn btn-lg btn-main" :handle-submit="submit" button-text="次へ進む" />
      </section>
      <section class="section inner-H inner-V">
        <button class="btn btn-bd-white" type="button" @click="historyBack">
          前の画面へ戻る
        </button>
      </section>
    </div>
    <Footer />
  </div>
</template>

<script>
export default {
  name: 'EKYCEntryCode',
  data: function() {
    return {
      //headerData
      pageName: '新規会員登録',
    };
  },
  computed: {
    eKYCInfo() {
      return this.$store.getters['eKYC/eKYCInfo'];
    },
  },
  methods: {
    async submit() {
      const res = await this.$store.dispatch('eKYC/setEKYCInfo');
      if(res.JWT) {
        await window.location.replace(`https://app.gmo-ekyc.com/entry/${res.JWT}`)
        sessionStorage.removeItem('registInfo')
      }
    },
  },
  beforeDestroy() {
    if (this.$route.name !== 'EKYCEntryConfirm') {
      this.$store.dispatch('customer/resetRegistInfo');
    }
  },
};
</script>
